import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swiper from "swiper/bundle";

const [w, d, mq] = [window, document, window.matchMedia("(min-width:900px)")];

const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.gnav();
    this.mainvisual();
    this.concept();
    this.plan();
    this.message();
    this.voice();
    this.qa();
    this.access();
    this.contact();
    this.formbtn();
    this.hashscroll();
  }

  gnav() {
    $('.rwd002-gnav-item__link').on('click', function(){
      $('.rwd002-gnav').removeClass('is-active');
      $('.rwd002-header__drawerBtn').removeClass('is-active');
    });
  }

  mainvisual() {
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      speed: 1200,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true,
      },
      autoplay:{
        delay: 6000,
        disableOnInteraction: false,
        waitForTransition: false
      }
    });

    let Mv = document.querySelector('.mainvisual');
    w.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });
  }

  concept() {
    const caseSlide = new Swiper('.case_slide',{
      slidesPerView: 1.2,
      spaceBetween: 19,
      speed: 800,
      navigation: {
        nextEl: '.case-next',
        prevEl: '.case-prev'
      },
      pagination: {
        el: '.case-pagination',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        450: {
          slidesPerView: 1.8,
        },
        900: {
          slidesPerView: 'auto',
          spaceBetween: 55,
        }
      }
    });
  }

  plan() {
    $('.block-plan .more_btn').on('click', function(){
      $(this).toggleClass('open');
      $('.block-plan .step_list').toggleClass('open');
    });
  }

  message() {
    if(!mq.matches){
      $('.block-message .more_btn').on('click', function(){
        $(this).toggleClass('open');
        $('.messa_list').toggleClass('open');
      });
    }
  }

  voice() {
    if(mq.matches){
      const voiceSlide = new Swiper('.voice_slide',{
        speed: 1200,
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: '.voice_next',
          prevEl: '.voice_prev'
        },
        pagination: {
          el: '.voice_pagination',
          type: 'bullets',
          clickable: true,
        }
      });
    } else {
      $('.block-voice .more_btn').on('click', function(){
        $(this).toggleClass('open');
        $('.voice_slide').toggleClass('open');
      });
    }
  }

  qa() {
    $('.qa_block .slide_trigger').on('click', function(){
      $(this).toggleClass('open');
      $(this).next('.detail_list').slideToggle();
    });
  }

  access() {
    if(!mq.matches){
      $('.block-access .more_btn').on('click', function(){
        $(this).toggleClass('open');
        $('.block-access .main_list').toggleClass('open');
      });
    }
  }

  contact() {
    $('.contact_btn').on('click', function(){
      let index = $('.contact_btn').index(this);
      $('.contact_btn').removeClass('current');
      $(this).addClass('current');

      $('.form_card').removeClass('current');
      $('.form_card').eq(index).addClass('current');
    });
  }

  formbtn() {
    $('.form_link_btn1').on('click', function(){
      $('#form_btn1').addClass('current');
      $('#form_btn2').removeClass('current');
      $('#form_card1').addClass('current');
      $('#form_card2').removeClass('current');
    });

    $('.form_link_btn2').on('click', function(){
      $('#form_btn1').removeClass('current');
      $('#form_btn2').addClass('current');
      $('#form_card1').removeClass('current');
      $('#form_card2').addClass('current');
    });
  }

  hashscroll() {
    $("a[href^='#']").on('click', function(){
      let href = $(this).attr("href");
      let target = $(href == "#" || href === "" ? "html" : href);
      let position = target.offset().top;
      $("body,html").stop().animate({
        scrollTop: position
      }, 500);
      return false;
    });
  }
}

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}